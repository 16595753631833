import { io, Socket } from 'socket.io-client';
import Cookies from 'universal-cookie';
import { Middleware } from 'redux';
import {
    addMessages,
    addRooms,
    loadMessages,
    sendMessage,
    updateMessage,
    searchRooms,
    updateRoom,
    updateReadMessage,
    reConnectSocket,
    uploadFile,
    updateRoomStatus,
    joinRoom,
    setToken,
    updateBlockWords,
    eventError,
    addBadgeTotal
} from '../store/slices/chatSlice'
import { RootState } from '@/store/store';
import Swal from "sweetalert2";
import { Message, UpdateRoomInc1, UpdateRoomInc2 } from './Interface/Chat.interface';
import { Config } from '@/config';

const URL = `${Config.apiChatEndpoint}`;




export const socketMiddleware: Middleware = ({ dispatch, getState }: any) => {

    let socket: Socket;
    let usertype = ''

    return (next: any) => (action: any) => {


        const isConnectionEstablished = (socket ? true : false)
        const state = getState() as RootState;
        const cookies = new Cookies();
        let token = cookies.get('token');

        if (setToken.type.match(action.type)) {
            token = action.payload.token
            usertype = action.payload.user_type
        }

        if ((setToken.type.match(action.type) && !isConnectionEstablished && token && usertype) || (usertype && token && !isConnectionEstablished)) {

            // console.log('Connecting Socket...', usertype)
            socket = io(URL, {
                query: {
                    auth_token: token,
                    user_type: usertype
                },
                reconnection: true,
                autoConnect: false,
                transports: ['websocket']
            })

            socket.removeAllListeners();
            socket.connect();
            socket.open();

            socket.on('connect', function () {
                const sessionID = socket.id;
                // console.log("Socket Connected", sessionID)
            });

            socket.on('error', (errorMessage: { message: string, isRemoveLastMessage?: boolean }) => {
                Swal.fire("", `${errorMessage.message}`, "warning");
                if (errorMessage.isRemoveLastMessage) {
                    dispatch(eventError({
                        isRemoveLastMessage: errorMessage.isRemoveLastMessage
                    }))
                }
            })

            socket.on('getBadgeTotal', (obj: { num: number, act: string }) => {
                dispatch(addBadgeTotal(obj))
            })

            socket.on('getRooms', (rooms) => {
                dispatch(addRooms(rooms))
            })

            socket.on('getBlockWords', (blockWord) => {
                dispatch(updateBlockWords(blockWord))
            })

            socket.on('getMessages', (messages) => {
                dispatch(addMessages(messages))
            })

            socket.on('searchRooms', (rooms) => {
                dispatch(addRooms(rooms))
            })

            socket.on('updateReadMessage', ({ roomId }) => {
                dispatch(updateReadMessage({}))
            })

            socket.on('isOnlineUser', ({ userId, isOnline }) => {
                dispatch(updateRoomStatus({
                    userId: userId,
                    isOnline: isOnline
                }))
            })

            socket.on('privateMessage', ({ reserveId, roomId, message }) => {
                dispatch(updateMessage({
                    message: message,
                    reserveId: reserveId,
                    roomId: roomId
                }));
            
                setTimeout(() => {
                    const savedMessage = localStorage.getItem(`chat_draft_${roomId}`);
                    if (savedMessage) {
                        const inputElement = document.getElementById('chat-input') as HTMLTextAreaElement;
                        if (inputElement) {
                            inputElement.value = savedMessage; // Restore draft in input field
                        }
                    }
                }, 100);
            });

            socket.on('updateChatRoom', (roomData: UpdateRoomInc1 | UpdateRoomInc2) => {

                dispatch(updateRoom(roomData))


            })

            socket.emit('getRooms')
            socket.emit('getBlockWords')
            socket.emit('getBadgeTotal')

        }





        if (isConnectionEstablished) {
            if (loadMessages.type.match(action.type) && isConnectionEstablished) {
                if (action.payload.roomId) {
                    socket.emit('getMessages', {
                        "skip": action.payload.skip || 0,
                        "limit": action.payload.limit || 20,
                        "roomId": action.payload.roomId
                    });
                }
                if (action.payload.participateId) {
                    // console.log("usertype", usertype)
                    socket.emit('joinRoom', {
                        userType: usertype == 'seller' ? 'buyer' : 'seller',
                        participateId: action.payload.participateId
                    })
                }


            }
            if (sendMessage.type.match(action.type) && isConnectionEstablished) {
                socket.emit('privateMessage', action.payload)
            }

            if (searchRooms.type.match(action.type) && isConnectionEstablished) {
                socket.emit('searchRooms', {
                    keyword: action.payload
                })
            }

            if (reConnectSocket.type.match(action.type) && isConnectionEstablished) {
                if (!socket.connected) {
                    socket.connect();
                }
            }

            if (uploadFile.type.match(action.type) && isConnectionEstablished) {
                socket.emit('uploadFile', action.payload)
            }

            if (joinRoom.type.match(action.type) && isConnectionEstablished) {
                if (action.payload?.userType) {
                    usertype = action.payload.userType
                }

                socket.emit('joinRoom', {
                    userType: usertype == 'seller' ? 'seller' : 'buyer',
                    participateId: action.payload.participateId,
                    productId: action.payload.productId,
                    storeId: action.payload.storeId,
                })
            }




        }
        // console.log('action=>', action)
        next(action)

    }
}