import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ChatRoom from '../../pages/chat'
import { useSelector,useDispatch } from 'react-redux';
import { clearAllBadgeTotal, setToken, updateRoom } from '@/store/slices/chatSlice';
import { doJoinRoom, getBadgeTotal, getOpenChatDialog } from '@/store/slices/chatSlice';
import { AccordionSummary, Divider, SvgIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/material/styles";
import Image from "next/legacy/image";
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import cookie from 'universal-cookie';
import Cookies from "universal-cookie";


const logo_chat = "/ChatIcon.png";
const FloatingActionButtonsBox = () => {

  // const actionJoinRoom = useSelector(doJoinRoom)
  const openChatDialog = useSelector(getOpenChatDialog)
  const [openBoxChat, setOpenBoxChat] = React.useState<boolean>(false);
  const badgeTotal = useSelector(getBadgeTotal)
  
  //start established socket io
  const cookies = new Cookies();
  const _token = cookies.get('token');


  const dispatch = useDispatch()
  dispatch(setToken({
    token: _token,
      user_type: 'buyer'
  }))
  //end established socket io
 
  

  React.useEffect(() => {
    if (openChatDialog !== 0) {            
      setOpenBoxChat(true)
    }
  }, [openChatDialog])

  const Wrapper = styled(Box)(({ theme }) => ({
    // [theme.breakpoints.down("sm")]: {
    //   minWidth: 150

    // },
    // [theme.breakpoints.down("md")]: {
    //   minWidth: 500
    // },
    // [theme.breakpoints.up("lg")]: {
    //   minWidth: 800
    // },
    // [theme.breakpoints.up("xl")]: {
    //   minWidth: 1000
    // },
  }));

  const PaperBox = styled(Paper)(({ theme }) => ({
    borderRadius: 3,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  }));

  const [isClient, setIsClient] = React.useState(false);//ปิดการทำงานก่อน
//เมื่อเสร็จแล้วค่อยทำงานส่วนข้างล่าง
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null; // Return null while waiting for the client-side rendering
  }

  const clickOpenChat = () => {     
      if(!openBoxChat){ // click chat  open
        setOpenBoxChat(true);
      }else { // click chat close
        dispatch(clearAllBadgeTotal(
          {
            totalUnread:0
          }
        ));
        setOpenBoxChat(false);
      }
  }

  
  
  return (
  
    <>
      <Box sx={{ position: "fixed", bottom: 15, right: 0, zIndex: 100, maxWidth: '800px', width: '100%' }}>
        {_token ? (
          openBoxChat ? (
            <Wrapper>
              <Paper className='box-chat' elevation={3} sx={{ borderRadius: 3 }}>
                <Box borderBottom={0} borderColor="divider">
                  <AccordionSummary
                    onClick={() => clickOpenChat()}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Stack direction={"row"}>
                      <FontAwesomeIcon icon={faCommentDots} style={{ fontSize: "18px", color: "#999999", marginTop: 3 }} />
                      <Typography sx={{ ml: 1 }}>Chat</Typography>
                    </Stack>
                  </AccordionSummary>
                </Box>
                <Divider />
                <ChatRoom />
              </Paper>
            </Wrapper>
          ) : (
            <Fab
              className='fab'
              onClick={() => clickOpenChat()}
              variant="extended"
              sx={{ borderRadius: 2, background: "#fff" }}
              style={{ position: "absolute", right: "0", bottom: "0" }}
            >
              <Stack direction="row" spacing={1}>
                <FontAwesomeIcon icon={faCommentDots} style={{ fontSize: "20px", color: "#999999", marginTop: 3 }} />
                <Typography style={{ textTransform: "none" }}>Chat</Typography>
                <Badge color="error" badgeContent={_.isNaN(badgeTotal) ? 0 : badgeTotal}></Badge>
                <KeyboardArrowUpIcon />
              </Stack>
            </Fab>
          )
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
export default FloatingActionButtonsBox;