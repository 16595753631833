import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { searchRooms } from '@/store/slices/chatSlice';

const SearchChat = () => {

    const dispatch = useDispatch();
    const [query, setQuery] = React.useState<string>("");

    React.useMemo(() => {
        const timeOutId = setTimeout(() => {
            dispatch(searchRooms(query))
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [query]);


    return (
        <TextField
            size="small"
            variant="outlined"
            placeholder="ค้นหา"
            fullWidth
            onChange={(e) => setQuery(e.target?.value)}
            sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent !important", // Removes border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent !important", // Removes hover effect border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent !important", // Removes focus border color
                    },
                },
            }}
            InputProps={{
                sx: {
                    borderRadius: "8px",
                    paddingLeft: 0,
                    marginRight: "0 !important",
                    border: "none", // Removes any inline border
                },
                startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: "0px !important" }}>
                        <IconButton 
                            sx={{ 
                                marginRight: 0,
                                "&:hover": {
                                    backgroundColor: "transparent"
                                }
                            }}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default SearchChat